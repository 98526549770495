@font-face {
    font-family: 'Nato Sans';
    src: local('Nato Sans Bold'), local('FiraSans-Bold'), url('NotoSans-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
 
@font-face {
    font-family: 'Nato Sans';
    src: local('Nato Sans Bold Italic'), local('FiraSans-BoldItalic'), url('NotoSans-BoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Nato Sans';
    src: local('Nato Sans  Italic'), local('FiraSans-Italic'), url('NotoSans-Italic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}


@font-face {
    font-family: 'Nato Sans';
    src: local('Nato Sans Regular '), local('FiraSans-Regular'), url('NotoSans-Regular.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
 

@font-face {
    font-family: 'Nato Sans';
    src: local('Nato Sans Regular '), local('FiraSans-Regular'), url('NotoSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Nato Sans';
    src: local('Nato Sans Regular '), local('FiraSans-Regular'), url('NotoSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
 
 @font-face {
    font-family: 'Nato Sans';
    src: local('Nato Sans Regular '), local('FiraSans-Regular'), url('NotoSans-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
} 
 @font-face {
    font-family: 'Nato Sans';
    src: local('Nato Sans Regular '), local('FiraSans-Regular'), url('NotoSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

 @font-face {
    font-family: 'Nato Sans';
    src: local('Nato Sans Regular '), local('FiraSans-Regular'), url('NotoSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
 @font-face {
    font-family: 'Nato Sans';
    src: local('Nato Sans Regular '), local('FiraSans-Regular'), url('NotoSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
} @font-face {
    font-family: 'Nato Sans';
    src: local('Nato Sans Regular '), local('FiraSans-Regular'), url('NotoSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
