@import url("fonts/nato-sans/nato-sans.css");
@import url("fonts/aliumi-sans/aliumi-sans.css");
body{
    font-family: 'Nato Sans', Verdana, Arial, sans-serif !important;
    text-align: left;
}
.feedback-widget {
    padding: 2px 0;
    display: flex;
    flex-direction: column;
}
.feedback-list{
    display: flex;
    overflow: hidden;
    scroll-behavior: smooth;
}

.feedback-widget-arrows{
    margin-top: -280px;
    z-index: 10;
    pointer-events: none;
}

.left_row_block, .right_row_block{
    height:282px;
    margin-top: -1px;
    width: 150px;
    display: flex;
    align-items: center;
    z-index: 10;
    pointer-events: all;
}


.left_row_block{
    float: left;
    background: linear-gradient(
            90deg, #ECEDEF 0%, rgba(236, 237, 239, 0) 100%);
    justify-content: flex-start;

}
.right_row_block{
    /*position: absolute;*/
    float: right;
    width: 150px;
    background: linear-gradient(270deg, #E8E8E8, transparent 70%, transparent 90%, transparent 200%);
    justify-content: flex-end;
}

.b-arrow-right, .b-arrow-left{
    background: url(FeedbackCard/images/round-arrow-white.svg);
    width: 36px;
    height: 36px;
    margin-right: 8px;
    cursor: pointer;
}

.b-arrow-left{
    transform: scale(-1, 1);
    margin-right: unset;
    margin-left: 8px;
}


.button-all {
    border-radius: 8px;
    border: unset;
    padding: 11px 24px;
    display: inline-block;

    font-weight: 500;
    text-decoration: unset;
    margin-top: 5px;
    letter-spacing: -0.41px;
    font-size: 15px;
    white-space: nowrap;
    cursor: pointer;
    background: transparent;
    border: 2px solid #000000;
    color: black;

}

.feedback-head{
    font-weight: 500;
    font-size: 32px;
    letter-spacing: -0.41px;
    color: #000;
    line-height: unset;
    margin-right: 20px;
}

.feedback-widget-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.feedback-widget-title__icon{
    background: url(FeedbackCard/images/feedback-star.svg) no-repeat center;
    margin: 0 12px;
    width: 34px;
    height: 31px;
}

.feedback-widget-title__icon-text{
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.408px;
    color: #5364FF;
}
.feedback-widget-title__text{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    letter-spacing: -0.41px;
}

.feedback-widget .flex{
    display: flex;
    align-items: center;
}

.hidden-arrow{
    opacity: 0;
}
