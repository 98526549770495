 
 
@font-face {
    font-family: 'Alumni Sans';
    src: local('AlumniSans Bold '), local('AlumniSans-Bold'), url('AlumniSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
 
 
@font-face {
    font-family: 'Alumni Sans';
    src: local('AlumniSans Thin '), local('AlumniSans-Thin'), url('AlumniSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
} 
@font-face {
    font-family: 'Alumni Sans';
    src: local('AlumniSans Thin '), local('AlumniSans-Thin'), url('AlumniSans-Light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Alumni Sans';
    src: local('AlumniSans Thin '), local('AlumniSans-Thin'), url('AlumniSans-Medium.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Alumni Sans';
    src: local('AlumniSans Thin '), local('AlumniSans-Thin'), url('AlumniSans-Regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

/*
AlumniSans-BlackItalic.ttf
AlumniSans-Bold.ttf
AlumniSans-BoldItalic.ttf
AlumniSans-ExtraBold.ttf
AlumniSans-ExtraBoldItalic.ttf
AlumniSans-ExtraLight.ttf
AlumniSans-ExtraLightItalic.ttf
AlumniSans-Italic.ttf
AlumniSans-Italic-VariableFont_wght.ttf

AlumniSans-LightItalic.ttf

AlumniSans-MediumItalic.ttf
AlumniSans-Regular.ttf
AlumniSans-SemiBold.ttf
AlumniSans-SemiBoldItalic.ttf

 
AlumniSans-VariableFont_wght.ttf
*/