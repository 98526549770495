div{
    text-align: left;
}
.feedback-card{
    width: 290px;
    min-width: 290px;
    background: #fff;
    margin-right: 10px;
    border: 1px solid #C9C9C9;
    border-radius: 12px;
    letter-spacing: -0.41px;
    cursor: pointer;
}

.feedback-card_container{
    padding: 20px;
    position: relative;
}
.feedback-card_title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    color: #000000;
    width: 100%;
    align-items: center;
    padding-bottom: 13px;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
}

.feedback-card_title div{
    width: 50%;
    height: 20px;
    overflow: hidden;
    line-height: 20px;

    color: #8A8A8A;
}
.feedback-card_title div:last-child{


    font-size: 15px;
    font-weight: 300;
    text-align: right;
    height: unset;
}
.feedback-card_title div:last-child {
    font-size: 14px;
    line-height: 17px;
    color: #888E99;
}

.feedback-card__text {
    padding-top: 10px;
    letter-spacing: -0.41px;
    color: #454545;
    height: 37px;
    overflow: hidden;
    font-weight: 100;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: unset;
}

.feedback-card__category{
    /*border-top: 1px solid #C6CAD2;*/
    /*border-bottom: 1px solid #C6CAD2;*/
    padding: 15px 0;
}
.feedback-card__category-divider{
    height: 1px;
    border-bottom: 1px solid #C9C9C9;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
}
.feedback-card__category div {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.408px;
    color: #888E99;
    font-weight: 300;
}

.feedback-card__category div:first-child{
    color: #000000;
}

.feedback-card__stars{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: 20px ;
}

.feedback-card__stars div {
    background: url(images/orange-star.svg) no-repeat center;
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.all-category:before{
    content: ' ';
    width: 130px;
    height: 50px;
    float: right;
    transform: scale(-1, 1);
    background-size: contain !important;
}
.category-500:before {
    background: url("images/category-1.svg") no-repeat right;
    margin-right: -25px;

}
.category-1000:before{
    background: url("images/category-2.svg") no-repeat right;
    margin-right: -25px;
}
.category-1500:before{
    background: url("images/category-3.svg") no-repeat right;
    margin-right: -10px;
}

.category-3000:before{
    background: url("images/category-4.svg") no-repeat right;
    margin-right: -17px;
}
.category-5000:before{
    background: url("images/category-5.svg") no-repeat right;
    margin-right: -8px;
}
.category-10000:before{
    background: url("images/category-6.svg") no-repeat right;
    margin-right: -10px;
}
.category-20000:before{
    background: url("images/category-7.svg") no-repeat right;
    margin-right: -10px;
}

.feedback-card__icon {
    font-size: 15px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: -0.41px;
    color: #FF813A;
    margin-top: 10px;
    margin-bottom: 20px;
}

.feedback-card__icon:before {
    content: ' ';
    background: url(images/map-pointer.svg);
    width: 16px;
    height: 18px;
    float: left;
    margin-right: 10px;
}
.feedback-card__category div.feedback-card__weight{
    font-family: 'Alumni Sans', Verdana, Arial, sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 20px;
    color: #000000;
    padding-bottom: 2px;
}
